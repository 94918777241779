import React from "react";
import ValueList from "../ValueList";
import { ScreenerFilterContext } from "Screener/Context";
import { createPortal } from "react-dom";
import { reactiveTableState } from "Screener/Table";
import { useContext } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { useRef } from "react";
import { useState } from "react";

const Value = ({
  indicator,
  portalReference,
}) => {
  const {
    dispatch,
    state,
  } = useContext(ScreenerFilterContext);

  const { loading } = useReactiveVar(reactiveTableState);

  const isPercentage = useMemo(() => {
    if (["growth", "percentage"].includes(indicator.indicatorRecord.unit)) {
      return true;
    } else {
      return false;
    }
  }, [indicator.indicatorRecord.unit])

  const valueToQuery = (value) => {
    if (value === undefined) { return value }
    if (value.match(/\d+/) === null) { return value }

    const coefficient = isPercentage ? 0.01 : 1;
    const parsedNumber = value.replaceAll(
      /[+-]?\d+(\.\d+)?/g,
      (match) => parseFloat(match) * coefficient
    );

    return parsedNumber;
  }

  const displayValue = useMemo(() => {
    const { value } = indicator;

    if (value === undefined) { return value }
    if (value.match(/\d+/) === null) { return value }

    const coefficient = isPercentage ? 100 : 1;
    const parsedNumber = value.replaceAll(
      /[+-]?\d+(\.\d+)?/g,
      (match) => parseFloat(match) * coefficient
    );

    return parsedNumber;
  }, [indicator.value]);

  const queryInputReference = useRef(null);
  const [indicatorValue, setIndicatorValue] = useState(displayValue);
  const [focused, setFocus] = useState(false);

  // Make sure this stays in sync with app/models/new_screener_tokens/value.rb
  const validRegexes = [
    /.+?\.{2,3}.+/,
    /([<>=]{1,2})(-?\d+(\.\d+)?)([bm]?)$/,
  ]

  const valueValid = indicatorValue === "" || validRegexes.some(rx => rx.test(indicatorValue));

  useEffect(() => {
    setIndicatorValue(displayValue);
  }, [indicator])

  useEffect(() => {
    if (!indicator.skipFocus) {
      dispatch({
        type: "DEFAULT",
        stateChanges: {
          filterBoxStyle: "value"
        }
      });
    }
  }, [dispatch, indicator]);

  const indicatorFromState = state.indicatorsInQuery.find((i) => {
    return indicator.shortcode === i.shortcode
  });

  const handleFocus = () => {
    setFocus(true);

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: "value"
      }
    });
  }

  const handleBlur = () => {
    setFocus(false);

    dispatch({
      type: "DEFAULT",
      stateChanges: {
        filterBoxStyle: ""
      }
    });
  }

  const setValueOnState = (value) => {
    dispatch({
      type: "SET_CRITERION_VALUE",
      id: indicator.id,
      shortcode: indicator.shortcode,
      value: valueToQuery(value),
    });
  }

  const handleChange = (event) => {
    event.preventDefault();

    if (/^[=><bm.-\d]*$/.test(event.target.value)) {
      setIndicatorValue(event.target.value);
    }
  }

  const handleKeypress = (event) => {
    switch (event.keyCode) {
      case 13: // ENTER
        if (valueValid) {
          setValueOnState(indicatorValue);
        } else {
          setIndicatorValue(indicatorFromState.value);
        }

        queryInputReference.current.blur();

        break;

      case 27: // ESC
        queryInputReference.current.blur();

        if (indicatorFromState) {
          setIndicatorValue(indicatorFromState.value);
        }

        break;

      default:
        break;
    }
  }

  const labelValue = useMemo(() => {
    const unitMapping = {
      "cash": "$",
      "cash ratio": "$",
      "price": "$",
      "percentage": "%",
      "growth": "%",
      "raw number": ""
    }[indicator.indicatorRecord.unit]

    if (unitMapping) {
      return (
        <div className="bg-slate-200 font-mono text-slate-900 px-2">
          {unitMapping}
        </div>
      );
    } else {
      return null;
    }
  }, [indicator.indicatorRecord.unit])

  return (
    <React.Fragment>
      <div className="mx-1">
        {indicator.indicatorRecord.name}
      </div>

      <div className="mx-3 flex">
        <input type="text"
          className="focus:outline-none border-none box-content text-center w-20 text-black font-mono"
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyUp={handleKeypress}
          ref={queryInputReference}
          value={indicatorValue}
          disabled={loading}
        />
        {labelValue}
      </div>

      {focused && createPortal(
        <ValueList
          indicator={indicator}
          valueValid={valueValid}
        />,
        portalReference.current
      )}
    </React.Fragment>
  );
}

export default Value;
