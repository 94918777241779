import PropTypes from "prop-types";
import React from "react";

const Heading = ({ variant, children }) => {
  let headingElement;
  const baseHeadingCss = `
    font-mono
    text-slate-900
  `;

  switch(variant) {
    case "small":
      const smallHeadingCss = `
        ${baseHeadingCss}
        pb-3
        text-base
      `;

      headingElement = (
        <h3
          className={smallHeadingCss}
          data-testid="heading-small"
        >
          {children}
        </h3>
      );

      break;
    case "tiny":
      const tinyHeadingCss = `
        ${baseHeadingCss}
        text-base
        pb-2
        text-sm
      `;

      headingElement = (
        <h4
          className={tinyHeadingCss}
          data-testid="heading-tiny"
        >
          {children}
        </h4>
      );

      break;
    case "large":
      const largeHeadingCss = `
        ${baseHeadingCss}
        text-2xl
        pb-5
      `;

      headingElement = (
        <h1
          className={largeHeadingCss}
          data-testid="heading-large"
        >
          {children}
        </h1>
      );

      break;
    default:
      const defaultHeadingCss = `
        ${baseHeadingCss}
        text-xl
        pb-2
      `;

      headingElement = (
        <h2
          className={defaultHeadingCss}
          data-testid="heading-default"
        >
          {children}
        </h2>
      );

      break;
  }

  return headingElement;
}

Heading.propTypes = {
  variant: PropTypes.oneOf(["large", "default", "small", "tiny"]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

Heading.defaultProps = {
  variant: "default",
}

export default Heading;
