import Heading from "common/Heading"
import News from "common/News";
import React from "react";
import { DESCRIPTOR_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const DescriptorNews = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading: loading,
    data: {
      descriptor: {
        name ="",
        shortcode ="",
      } ={}
    } ={}
  } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  if (loading) { return null; }

  const newsProps = {
    initialState: {
      coverage: ["specific", "earnings", "transcript"],
      showJustification: true
    },
    shortcode: descriptorShortcode,
  }

  return (
    <React.Fragment>
      <Heading>
        {name} ({shortcode}) Latest News
      </Heading>

      <News {...newsProps} />
    </React.Fragment>
  )
}

export default DescriptorNews;
