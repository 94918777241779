import React from "react";
import { Helmet } from "react-helmet";

const DocumentHeader = ({
  newsArticles
}) => {
  let articles = newsArticles.map((article) => {
    return {
      "@context": "http://schema.org",
      "@type": "Article",
      "name": article.title,
      "datePublished": article.publishedAt,
      "articleBody": `${article.text} ${article.justification}`,
      "author": [
        {
          "@type": "Organization",
          "name": article.source
        }
      ]
    }
  })

  return (
    <React.Fragment>
      <Helmet>
        <script type="application/ld+json">
          {`
            ${JSON.stringify(articles)}
          `}
        </script>
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
