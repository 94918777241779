import Heading from "common/Heading";
import React from "react";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const RecommendationRating = () => {
  const {
    recommendationRating
  } = useContext(KeystatsContext);

  let arrowColor;
  let fontColor;

  switch(true) {
    case (recommendationRating < 2): arrowColor = "#008f88"; break;
    case (recommendationRating < 3): arrowColor = "#00c073"; break;
    case (recommendationRating < 4): arrowColor = "#ffdc48"; break;
    case (recommendationRating < 5): arrowColor = "#ffa33e"; break;
    default: arrowColor = "#ff333a"; break;
  };

  switch(true) {
    case (recommendationRating < 2): fontColor = "#ffffff"; break;
    case (recommendationRating < 3): fontColor = "#ffffff"; break;
    case (recommendationRating < 4): fontColor = "#000000"; break;
    case (recommendationRating < 5): fontColor = "#ffffff"; break;
    default: fontColor = "#ffffff"; break;
  };

  const arrowStyle = {
    left: `calc(${(recommendationRating - 1) * 25}% - 15px)`,
    top: "-35px",
    width: "30px",
    height: "20px",
    paddingTop: "2px",
    backgroundColor: arrowColor,
    color: fontColor,
  }

  const tipStyle = {
    width: "0px",
    height: "0px",
    borderColor: "green",
    position: "absolute",
    bottom: "-5px",
    left: "10px",
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: `5px solid ${arrowColor}`,
  }

  return (
    <div>
      <Heading>
        Recommendation Rating
      </Heading>

      <div className="px-5 py-3 pt-12">
        <div className="relative w-full bg-neutral-500 relative" style={{ height: "2px" }}>
          {recommendationRating >= 1 && (
            <div className="bg-neutral-500 absolute text-white text-xs font-mono text-center" style={arrowStyle}>
              {recommendationRating}

              <div style={tipStyle} />
            </div>
          )}

          <div className="absolute w-full" style={{ height: "10px", top: "-4px" }}>
            <div className="grid grid-cols-4 h-full">
              <div className="border-r-2 border-l-2 border-neutral-500 h-full"></div>
              <div className="border-r-2 border-neutral-500 h-full"></div>
              <div className="border-r-2 border-neutral-500 h-full"></div>
              <div className="border-r-2 border-neutral-500 h-full"></div>
            </div>
          </div>
        </div>

        <div className="w-full text-center">
          <div className="relative w-1/4 inline-block" style={{ fontSize: "0.6rem" }}>
            <div className="absolute w-full" style={{ left: "-50%" }}>
              <span className="font-mono">1</span><br />Buy
            </div>
          </div>

          <div className="relative w-1/4 inline-block" style={{ fontSize: "0.6rem" }}>
            <div className="absolute w-full" style={{ left: "-50%" }}>
              <span className="font-mono">2</span>
            </div>
          </div>

          <div className="relative w-1/4 inline-block" style={{ fontSize: "0.6rem" }}>
            <div className="absolute w-full" style={{ left: "-50%" }}>
              <span className="font-mono">3</span><br />Hold
            </div>
          </div>

          <div className="relative w-1/4 inline-block" style={{ fontSize: "0.6rem" }}>
            <div className="absolute w-full" style={{ left: "-50%" }}>
              <span className="font-mono">4</span>
            </div>

            <div className="absolute w-full" style={{ right: "-50%" }}>
              <span className="font-mono">5</span><br />Sell
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecommendationRating;
