import FormattedNumber from "common/FormattedNumber";
import Heading from "common/Heading";
import PropTypes from "prop-types";
import React from "react";
import TableLegend from "common/TableLegend";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const QuarterlyTable = ({
  dataKey,
  heading,
  dataUnit,
}) => {
  const quarterlyData = useContext(KeystatsContext)[dataKey];

  return (
    <div>
      <Heading>
        {heading}
      </Heading>

      <table
        className="w-full table-fixed border-spacing-0"
      >
        <thead>
          <tr>
            <th style={{ width: "20px" }}>
            </th>

            {quarterlyData.headers.map((header, index) => (
              <th
                className="w-auto text-xs text-right font-normal font-mono text-slate-400 pb-1"
                key={index}
              >
                {header}
              </th>
            ))}
            <th className="w-auto text-right text-xs font-normal font-mono text-slate-400 pb-1">
              Year
            </th>
          </tr>
        </thead>

        <tbody>
          {quarterlyData.rows.map((row, rowIndex) => (
            <tr key={rowIndex} className="border-spacing-0">
              <td className="text-xs font-mono text-slate-400 p-0">
                {row.label}
              </td>

              {row.values.map((value, index) => (
                <td
                  className="text-right p-0"
                  key={index}
                >
                  <FormattedNumber
                    number={value}
                    unit={dataUnit}
                  />
                </td>
              ))}

              <td className="text-right p-0">
                <div className="bg-slate-100 py-0.5 pr-2 -mr-2 ml-2">
                  <FormattedNumber
                    number={row.totals}
                    unit={dataUnit}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {dataUnit === "cash" && (
        <TableLegend>
          in millions of $
        </TableLegend>
      )}
    </div>
  )
}

QuarterlyTable.propTypes = {
  heading: PropTypes.string,
  quarterlyData: PropTypes.object,
  dataUnit: PropTypes.string
};

QuarterlyTable.defaultProps = {
  dataUnit: "cash"
};

export default QuarterlyTable;
