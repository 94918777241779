import Heading from "common/Heading";
import React from "react";
import Table from "./AssetsLiabilities/Table";
import TableLegend from "common/TableLegend";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const AssetsLiabilities = () => {
  const {
    assetsLiabilities
  } = useContext(KeystatsContext);

  return (
    <div>
      <Heading>
        Assets vs Liabilities
      </Heading>

      <div className="flex flex-col gap-7">
        {assetsLiabilities.map((tableData, index) => (
          <Table
            key={index}
            tableData={tableData}
          />
        ))}
      </div>

      <TableLegend>
        in millions of $
      </TableLegend>
    </div>
  )
}

export default AssetsLiabilities;
