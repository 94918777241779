import Heading from "common/Heading";
import React from "react";
import Price from "./TargetPriceRange/Price";
import { KeystatsContext } from "KeystatsReport/Context";
import { useContext } from "react";

const TargetPriceRange = () => {
  const {
    targetPrice
  } = useContext(KeystatsContext);

  return (
    <div>
      <Heading>
        Target Price Range
      </Heading>

      <Price
        label="High"
        change={targetPrice.high_change}
        value={targetPrice.high}
        variant="green"
      />

      <Price
        label="Average"
        change={targetPrice.mean_change}
        value={targetPrice.mean}
        variant="yellow"
      />

      <Price
        label="Low"
        change={targetPrice.low_change}
        value={targetPrice.low}
        variant="red"
      />
    </div>
  )
}

export default TargetPriceRange;
