import DescriptorHeader from "DescriptorHeader";
import DescriptorNavigation from "DescriptorNavigation";
import React from "react";
import { Helmet } from "react-helmet";
import { WIKI_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

const BusinessProfile = () => {
  const { descriptorShortcode } = useParams();

  const slug = `${descriptorShortcode.toLowerCase()}-business-profile`;

  const {
    data: {
      wikiPage: {
        description ="",
        html ="",
        text ="",
        keywords ="",
        title ="",
        createdAt,
        updatedAt
      } ={}
    } ={}
  } = useQuery(WIKI_QUERY, {
    variables: {
      slug,
    }
  });

  let businessProfileHeader = {
    "@context": "http://schema.org",
    "@type": "Article",
    "name": title,
    "articleBody": text,
    "datePublished": createdAt,
    "dateModified": updatedAt,
    "description": description,
    "url": `https://stockrow.com/${descriptorShortcode}/business-profile`,
    "author": [
      {
        "@type": "Organization",
        "name": "stockrow.com",
        "url": "https://stockrow.com"
      }
    ]
  }

  const articleClass = `
    [&>h2]:text-3xl
    [&>h2]:mt-7
    [&>h2]:mb-5
    [&>h2]:font-mono

    [&>h3]:text-xl
    [&>h3]:mt-5
    [&>h3]:mb-1
    [&>h3]:font-mono

    [&>ul]:list-disc
    [&>ul]:mb-10
    [&>ul>li]:text-base
    [&>ul>li]:mb-1

    [&>ol]:list-decimal
    [&>ol]:mb-10
    [&>ol>li]:text-base
    [&>ol>li]:mb-1

    [&>p]:mb-3
    [&>p]:text-lg

    [&_span.katex-html]:hidden
    [&_span.katex]:my-5
    [&_span.katex]:block
  `;

  return (
    <React.Fragment>
      <Helmet>
        <title>{title} — stockrow</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            ${JSON.stringify(businessProfileHeader)}
          `}
        </script>
      </Helmet>

      <DescriptorHeader />

      <div className="hidden md:block">
        <DescriptorNavigation />
      </div>

      <div className="w-full m-auto bg-amber-50 p-2">
        <div
          itemScope
          itemType="http://schema.org/Article"
          className="2xl:w-1/2 xl:w-4/6 m-auto p-10 bg-white"
        >
          <h1
            className="text-4xl mb-5 font-mono"
            itemProp="name headline"
          >
            {title}
          </h1>

          <span
            className="hidden"
            itemProp="datePublished"
          >
            {createdAt}
          </span>

          <span
            className="hidden"
            itemProp="dateModified"
          >
            {updatedAt}
          </span>

          <span
            className="hidden"
            itemProp="publisher"
            itemScope
            itemType="http://schema.org/Organization"
          >
            <span itemProp="name">
              stockrow.com
            </span>
          </span>

          <span
            itemProp="url"
            className="hidden"
          >
            {`https://stockrow.com/${descriptorShortcode}/business-profile`}
          </span>

          <div
            itemProp="articleBody"
            className={articleClass}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default BusinessProfile;
