import AnalystCommentary from "./AnalystCommentary";
import Chart from "Chart";
import DescriptorNews from "./DescriptorNews";
import FinancialsTable from "KeystatsReport/FinancialsTable";
import React from "react";
import RecommendationRating from "KeystatsReport/RecommendationRating";
import TargetPriceRange from "KeystatsReport/TargetPriceRange";
import { DESCRIPTOR_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const Main = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading: descriptorLoading,
    data: {
      descriptor: {
        analystCommentary = false,
      } ={}
    } ={}
  } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  const chartParams = [
    {
      name: "Daily Price",
      series: [
        {
          descriptor: `${descriptorShortcode}:company`,
          indicator: "DAILY_PRICE:D",
          type: "candlestick",
          primary: true
        }
      ]
    }
  ]

  const chartOptions = {
    navigator: {
      enabled: true
    },
    rangeSelector: {
      enabled: true,
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1m",
          title: "View 1 month"
        },
        {
          type: "month",
          count: 3,
          text: "3m",
          title: "View 3 months"
        },
        {
          type: "month",
          count: 6,
          text: "6m",
          title: "View 6 months"
        },
        {
          type: "ytd",
          text: "YTD",
          title: "View year to date"
        },
        {
          type: "year",
          count: 1,
          text: "1y",
          title: "View 1 year"
        },
        {
          type: "year",
          count: 3,
          text: "3y",
          title: "View 3 years"
        },
        {
          type: "year",
          count: 5,
          text: "5y",
          title: "View 5 years"
        },
        {
          type: "all",
          text: "All",
          title: "View all"
        }
      ]
    },
  }

  return (
    <React.Fragment>
      <div className="flex flex-col md:flex-row pb-10">
        <div className="grow">
          <Chart
            chartParams={chartParams}
            options={chartOptions}
            height="570px"
          />
        </div>

        <div className="flex-none md:w-[230px] flex flex-col gap-7">
          <TargetPriceRange />

          <RecommendationRating />
        </div>
      </div>

      <div className="w-full grid gap-5">
        <div className="w-full overflow-scroll">
          <FinancialsTable />
        </div>

        {!descriptorLoading && analystCommentary && (
          <AnalystCommentary />
        )}

        <DescriptorNews />
      </div>
    </React.Fragment>
  )
}

export default Main;
