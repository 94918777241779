import React from "react";
import { DESCRIPTOR_QUERY } from "queries";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';

const DocumentHeader = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading: loading,
    data: {
      descriptor: {
        name ="",
        shortcode ="",
        sectorName ="",
        industryName ="",
      } ={}
    } ={}
  } = useQuery(DESCRIPTOR_QUERY, {
    variables: {
      shortcode: descriptorShortcode,
    }
  });

  if (loading) { return null; }

  const description =`Gain valuable insights with in-depth analysis and
  performance evaluation of ${name} (${shortcode}). Access crucial financial data,
  forecasts, and company news to empower investors in making well-informed
  decisions regarding potential opportunities in the dynamic stock market.
  `;

  return (
    <React.Fragment>
      <Helmet>
        <title>{name} ({shortcode}) Key Financial Statistics and Analysis - stockrow</title>
        <meta name="description" content={description} />
      </Helmet>
    </React.Fragment>
  )
}

export default DocumentHeader;
