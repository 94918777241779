import Heading from "common/Heading";
import Link from "common/Link";
import React from "react";
import { DESCRIPTOR_QUERY } from "queries";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { WIKI_QUERY } from "queries";

const AnalystCommentary = () => {
  const { descriptorShortcode } = useParams();

  const {
    loading: commentaryLoading,
    data: {
      wikiPage: {
        html ="",
      } ={}
    } ={}
  } = useQuery(WIKI_QUERY, {
    variables: {
      slug: `${descriptorShortcode.toLowerCase()}-analyst-commentary-summary`,
    }
  });

  const articleClass = `
    [&>ul]:list-disc
    [&>ul]:mb-10
    [&>ul>li]:text-base
    [&>ul>li]:mb-1

    [&>ol]:list-decimal
    [&>ol]:mb-10
    [&>ol>li]:text-base
    [&>ol>li]:mb-1

    [&>p]:mb-3
    [&>p]:text-lg
  `;

  if (commentaryLoading) { return null; }

  const commentaryUrl = `/${descriptorShortcode}/analyst-commentary`;

  return (
    <div className="bg-amber-50 p-5">
      <div>
        <Heading>
          Analyst Commentary (Summary)
        </Heading>
      </div>

      <div className="flex flex-col lg:flex-row gap-5">
        <div className="w-full lg:w-4/6">
          <div
            className={articleClass}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>

        <div className="w-full lg:w-2/6">
          <Link url={commentaryUrl}>
            Read full commentary →
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AnalystCommentary;
